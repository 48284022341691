.react-datepicker__input-container > input{
  width: 120px !important;
  padding: 10px !important;
  border-radius: 4px !important;
  background: #FFFFFF !important;
  border: 1px solid #BFBFBF !important;
  color: #262626 !important;
  font-weight: 500 !important;
  box-sizing: border-box !important;
  text-align: center !important;
}

.react-datepicker {
  font-family: 'Pretendard' !important;
}

.react-datepicker__header {
  border-bottom : none;
  background: #f4f7ff !important;
  padding: 12px 0;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background: #93afff !important;
  border-radius: 32px;
}

.react-datepicker__day--keyboard-selected {
  background: #e3eaff !important;
  border-radius: 32px;
}

.react-datepicker__day:hover {
  border-radius: 32px;
  background: #e3eaff !important;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover {
  border-radius: 32px;
  background: #93afff !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #333 !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: #e8eeff !important;
}