@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Noto+Sans+KR:wght@400;500;600;700;800;900&display=swap');
*, body{
  font-family: 'Pretendard';
}

a {
  text-decoration: none;
}

div, button {
  box-sizing: border-box;
}

button{
  border: none;
  background: none;
  cursor: pointer;
}

input {
  outline: none;
}

.ir {
  display: block;
  overflow: hidden;
  position: absolute;
  text-indent: -9999px;
  line-height: 0;
  font-size: 1px;
  color: transparent;
}

::selection {
  background-color: #40bdae;
  color: #fff;
}