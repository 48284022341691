.section-rullet {
  display: block;
  padding: 0 20px 0px;
  margin: 30px auto;
  max-width: 480px;
}

.event-rullet {
  /* margin-top: 100px; */
}
.rullet-title {
  display: block;
  width: 215px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #d348ff;
  text-align: center;
  margin: 0 auto;
  padding: 8px 16px;
  border-radius: 16px;
}
.rouletter .wrap-result {
  position: relative;
}
.rouletter .wrap-result .fail-img{
  max-width: 350px;
  margin: 0 auto 40px;
  display: block;
}
.rouletter .wrap-result > button {
  display: block;
  position: absolute;
  bottom: 40px;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  border: none;
  background: #4D4D4D;
  border-radius: 32px;
  color: #fff;
  line-height: 1;
  padding: 6px 0;
  box-sizing: border-box;
  cursor: pointer;
}
.event-rullet .mobile-btn {
  display: block;
  background: none;
  border: none;
  margin: 0 auto 30px;
  cursor: pointer;
}

.event-rullet h2 {
  font-size: 32px;
  font-weight: 900;
  text-align: center;
  padding-bottom: 12px;
  animation: neon 0.8s ease infinite;
  -moz-animation: neon 0.8s ease infinite;
  -webkit-animation: neon 0.8s ease infinite;
}

@keyframes neon {
  0%,
  100% {
    text-shadow: 0 0 0.3vw rgb(173, 72, 255);
    color: rgb(121, 0, 235);
  }
  50% {
    text-shadow: 0 0 0.3vw rgb(209, 154, 255);
    color: rgb(188, 72, 255);
  }
}
.event-rullet h3.sub-title {
  font-size: 18px;
  color: #555;
  font-weight: 400;
  text-align: center;
  line-height: 1.5;
}
.event-rullet .rouletter {
  position: relative;
  width: 350px;
  height: 350px;
  margin: 40px auto;
}
.event-rullet .rouletter-bg {
  width: 350px;
  height: 350px;
  border-radius: 350px;
  overflow: hidden;
}
.event-rullet .rouletter-wacu {
  width: 100%;
  height: 100%;
  background: #f5f5f2 url("../assets/images/rullet.png") no-repeat;
  background-size: 100%;
  transform-origin: center;
}

.event-rullet .rouletter-arrow {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}
.event-rullet .rouletter-arrow > img {
  width: 55px;
}
.event-rullet .rouletter-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85px;
  height: 85px;
  border-radius: 80px;
  background: #ffae00;
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  animation: blink-effect 0.8s ease-in infinite;
}

@keyframes blink-effect {
  50% {
    opacity: 0.8;
    background: #ffcf4d;
  }
}